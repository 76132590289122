export interface CurrentUser {
	loggedIn: boolean;
	username?: string;
}

export interface ApplicationTerms {
	terms: TermData[];
}

export interface FormParameterDTO {
	formData: string;
	formValue: string;
}

export interface Address {
	address1: string;
	address2: string | null;
	city: string;
	state: string;
	zip: string;
}

export function instanceOfMailingAddress(object: unknown): object is Address {
	return (
		object != null &&
		typeof object === 'object' &&
		'address1' in object &&
		object.address1 !== undefined &&
		'city' in object &&
		object.city !== undefined &&
		'state' in object &&
		object.state !== undefined &&
		'zip' in object &&
		object.zip !== undefined
	);
}

export interface WelcomeLinks {
	icon: string;
	text: string;
	url: string;
}

/**
 * - When application is delayed, redirects to `/applicationDelayed`; this page
 *   is seen before accepted or denied SIR; depending on their choice, go to
 *   `/acceptedSpringOffer` or `/declinedSpringOffer`
 *
 * - `/applicationWaitlisted` - choose the option; redirect to `/acceptedWaitlist`
 *   and `declinedWaitlist`; OR there's also for missed deadlines,
 *   `/missedWaitlistDeadline`
 *
 * - When an application is pending - no decision has been made???
 *
 * @link https://github.com/ucm-it/sir/blob/main/src/main/java/edu/ucmerced/sir/web/dto/ApplicantPageDTO.java
 */
export interface TermData {
	/**
	 * what page to send current user to
	 */
	page: string;
	/**
	 * 6-digit regex-ish: YYYY(01|02|03|04) [01 - spring; 02 - summer; 03 - fall; 04 - winter]
	 */
	termcode: string | null;
	ucmnetid: string | null;
	/**
	 * Only used when an application is accepted
	 */
	paymentState: null | 'deferred' | 'required' | 'incomplete';
	/**
	 * Only used when an application is cancelled/turned down. "none" ~=? null
	 */
	cancelledState: null | 'cancelled' | 'rescinded' | 'declined' | 'none';
	/**
	 * Applicant explicitly declined the app.
	 *
	 * TODO: confirm: "if this is true, then `cancelledState` should be 'declined'"
	 *
	 * coming from PERSONAL_INFO view
	 */
	declinedSir: boolean;
	applicationNumber: number | null;
	/**
	 * active - student has accepted offer and the session has started
	 * inactive - student has accepted but not started the session
	 */
	sirStatus: 'inactive_student' | 'active_student' | null;
	studentId: string | null;
	/**
	 * SIR deadline
	 */
	sirDate: string | Date | null;
	/**
	 * Student name
	 */
	name: string | null;
	/**
	 * student nickname
	 */
	nickname: string | null;
	/**
	 * Should have a value since the moment is SIR open to be accepted
	 */
	residency:
		| null
		| 'Resident'
		| 'Nonresident'
		| 'Undetermined'
		| 'Conditional Exemption';
	/**
	 * Should have value at time of SIR being accepted
	 */
	isWueOffered: boolean | null;
	/**
	 * Available if `isWueOffered` is true
	 */
	wueOfferUrl: string | null;
	/**
	 * Available if `residency` is 'Undetermined'
	 */
	undeterminedResidencyUrl: string | null;
	mailingAddress: null | Address;
	physicalAddress: null | Address;
	/**
	 * (Fall|Spring|Summer|Winter) YYYY
	 */
	admissionTerm: string | null;
	degree: string | null;
	major: string | null;
	/**
	 * Has $ included in the string
	 */
	sirFee: string | null;
	/**
	 * URL to request downloading an admission letter (this can take a minute)
	 */
	admissionsLetterUrl: string | null;
	welcomeLinks: WelcomeLinks[];
	/**
	 * Always returned in response
	 */
	depositWaiverUrl: string;
	/**
	 * TODO: confirm "Only returned if `paymentState` is 'required'"
	 * coming from PERSONAL_INFO view
	 */
	payDepositUrl: string | null;
	/**
	 * Will behave as hidden input fields? (I think)
	 * four elements in here:
	 * 1. custcode - ucmnetid
	 * 2. signouturl - URL to redirect back to SIR app (base url)
	 * 3. incompletesignouturl - same value as `signouturl`
	 * 4. studentid - studentId
	 */
	payParameters: null | FormParameterDTO[];
	/**
	 * if test user or if `declineSurveyUrl` is V2, this is an empty array
	 * if V1, five elements in this array
	 * 1. appno - application number
	 * 2. termcode - see above
	 * 3. studentclass
	 * 4. pidm
	 * 5. apdccode
	 */
	declineSurveyParameters: null | FormParameterDTO[];
	financialAidDetailsUrl: string | null;
	appealLateSirUrl: string;
	appealDenialUrl: string;
	appealDenialText: string;
	admissionsEmail: string;
	admissionsPhoneNumber: string;
	admissionsUrl: string;
	studentPortalUrl: string;
	acceptSurveyUrl: string | null;
	/**
	 * there's a V1 and V2 of this URL; set by the BE; defaults to V2
	 */
	declineSurveyUrl: string | null;
	sirInstructionsUrl: string;
	sirInstructionsText: string;
	/**
	 * If applicant got "delayed," then this will be set and different
	 * from `admissionTerm`.
	 *
	 * re: (Fall|Spring|Summer|Winter)\sYYYY
	 */
	futureAdmissionTerm: string | null;
	waitListDueDate: string;
	paymentCompletedDuringSession: boolean;
}

export enum SemesterTermCode {
	Spring = '10',
	Summer = '20',
	Fall = '30',
	Winter = '40',
}
