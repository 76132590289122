import { ApplicationTerms } from '../src/contracts';
import api from './core';

export async function fetchAllApplications(): Promise<ApplicationTerms> {
	const res = await api.get<ApplicationTerms>('/applications', {
		withCredentials: true,
	});

	if (typeof res.data !== 'object') {
		throw new Error('API Error - ' + res.data);
	}

	return res.data;
}

interface TermCode {
	termcode: string;
}

const applicationEndpoints = {
	accept: '/acceptOffer',
	acceptSpring: '/acceptSpringOffer',
	acceptWaitlist: '/acceptWaitlist',
	cancel: '/cancelApplication',
	cancelSir: '/cancelSir',
	decline: '/declineOffer',
	declineSpring: '/declineSpringOffer',
	declineWaitlist: '/declineWaitlist',
	pay: '/payDeposit',
};

async function updateAdmissionOffer(
	termCode: string,
	action: keyof typeof applicationEndpoints,
): Promise<TermCode> {
	const formData = new FormData();
	formData.append('termcode', termCode);

	const response = await api.post<TermCode>(
		applicationEndpoints[action],
		formData,
		{
			withCredentials: true,
		},
	);

	return {
		...response.data,
		termcode: termCode,
	};
}

export async function acceptAdmissionOffer(
	termCode: string,
): Promise<TermCode> {
	return await updateAdmissionOffer(termCode, 'accept');
}

export async function acceptSpringAdmissionOffer(
	termCode: string,
): Promise<TermCode> {
	return await updateAdmissionOffer(termCode, 'acceptSpring');
}

export async function acceptWaitlistOffer(termCode: string): Promise<TermCode> {
	return await updateAdmissionOffer(termCode, 'acceptWaitlist');
}

export async function declineAdmissionOffer(
	termCode: string,
): Promise<TermCode> {
	return await updateAdmissionOffer(termCode, 'decline');
}

export async function declineSpringAdmissionOffer(
	termCode: string,
): Promise<TermCode> {
	return await updateAdmissionOffer(termCode, 'declineSpring');
}

export async function declineWaitlistOffer(
	termCode: string,
): Promise<TermCode> {
	return await updateAdmissionOffer(termCode, 'declineWaitlist');
}

export async function cancelApplication(termCode: string): Promise<TermCode> {
	return await updateAdmissionOffer(termCode, 'cancel');
}

export async function cancelSir(termCode: string): Promise<TermCode> {
	return await updateAdmissionOffer(termCode, 'cancelSir');
}

export async function payDeposit(termCode: string): Promise<TermCode> {
	return await updateAdmissionOffer(termCode, 'pay');
}

export async function getAdmissionsLetter(downloadURL: string): Promise<Blob> {
	const response = await api.get<Blob>(downloadURL, {
		responseType: 'blob',
		withCredentials: true,
	});

	const link = document.createElement('a');
	link.href = window.URL.createObjectURL(response.data);
	link.setAttribute('download', 'AdmissionLetter.pdf');
	link.click();

	return response.data;
}
